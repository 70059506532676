import macro from '../../macros.js';
import { u as uninitializeBounds } from '../Core/Math/index.js';
import vtkPoints from '../Core/Points.js';

// vtkCell methods
// ----------------------------------------------------------------------------

function vtkCell(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkCell');

  publicAPI.initialize = function (points) {
    var pointIdsList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (!pointIdsList) {
      model.points = points;
      model.pointsIds = new Array(points.getNumberOfPoints());

      for (var i = points.getNumberOfPoints() - 1; i >= 0; --i) {
        model.pointsIds[i] = i;
      }
    } else {
      model.pointsIds = pointIdsList;
      var triangleData = model.points.getData();

      if (triangleData.length !== 3 * model.pointsIds.length) {
        triangleData = macro.newTypedArray(points.getDataType(), 3 * model.pointsIds.length);
      }

      var pointsData = points.getData();
      model.pointsIds.forEach(function (pointId, index) {
        // const start = 3 * pointId;
        // pointsData.set(p.subarray(start, start + 3), 3 * index);
        var pointOffset = 3 * pointId;
        var trianglePointOffset = 3 * index;
        triangleData[trianglePointOffset] = pointsData[pointOffset];
        triangleData[++trianglePointOffset] = pointsData[++pointOffset];
        triangleData[++trianglePointOffset] = pointsData[++pointOffset];
      });
      model.points.setData(triangleData);
    }
  };

  publicAPI.getBounds = function () {
    var nbPoints = model.points.getNumberOfPoints();
    var x = [];

    if (nbPoints) {
      model.points.getPoint(0, x);
      model.bounds[0] = x[0];
      model.bounds[1] = x[0];
      model.bounds[2] = x[1];
      model.bounds[3] = x[1];
      model.bounds[4] = x[2];
      model.bounds[5] = x[2];

      for (var i = 1; i < nbPoints; i++) {
        model.points.getPoint(i, x);
        model.bounds[0] = x[0] < model.bounds[0] ? x[0] : model.bounds[0];
        model.bounds[1] = x[0] > model.bounds[1] ? x[0] : model.bounds[1];
        model.bounds[2] = x[1] < model.bounds[2] ? x[1] : model.bounds[2];
        model.bounds[3] = x[1] > model.bounds[3] ? x[1] : model.bounds[3];
        model.bounds[4] = x[2] < model.bounds[4] ? x[2] : model.bounds[4];
        model.bounds[5] = x[2] > model.bounds[5] ? x[2] : model.bounds[5];
      }
    } else {
      uninitializeBounds(model.bounds);
    }

    return model.bounds;
  };

  publicAPI.getLength2 = function () {
    publicAPI.getBounds();
    var length = 0.0;
    var diff = 0;

    for (var i = 0; i < 3; i++) {
      diff = model.bounds[2 * i + 1] - model.bounds[2 * i];
      length += diff * diff;
    }

    return length;
  };

  publicAPI.getParametricDistance = function (pcoords) {
    var pDist;
    var pDistMax = 0.0;

    for (var i = 0; i < 3; i++) {
      if (pcoords[i] < 0.0) {
        pDist = -pcoords[i];
      } else if (pcoords[i] > 1.0) {
        pDist = pcoords[i] - 1.0;
      } else {
        // inside the cell in the parametric direction
        pDist = 0.0;
      }

      if (pDist > pDistMax) {
        pDistMax = pDist;
      }
    }

    return pDistMax;
  };

  publicAPI.getNumberOfPoints = function () {
    return model.points.getNumberOfPoints();
  };

  publicAPI.deepCopy = function (cell) {
    cell.initialize(model.points, model.pointsIds);
  };

  publicAPI.getCellDimension = function () {}; // virtual


  publicAPI.intersectWithLine = function (p1, p2, tol, t, x, pcoords, subId) {}; // virtual


  publicAPI.evaluatePosition = function (x, closestPoint, subId, pcoords, dist2, weights) {
    macro.vtkErrorMacro('vtkCell.evaluatePosition is not implemented.');
  }; // virtual

} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  bounds: [-1, -1, -1, -1, -1, -1],
  pointsIds: []
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  macro.obj(publicAPI, model);

  if (!model.points) {
    model.points = vtkPoints.newInstance();
  }

  macro.get(publicAPI, model, ['points', 'pointsIds']);
  vtkCell(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkCell'); // ----------------------------------------------------------------------------

var vtkCell$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkCell$1 as default, extend, newInstance };

var IntersectionState = {
  NO_INTERSECTION: 0,
  YES_INTERSECTION: 1,
  ON_LINE: 2
};
var Constants = {
  IntersectionState: IntersectionState
};

export { IntersectionState, Constants as default };

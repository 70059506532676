var PassTypes = {
  MIN_KNOWN_PASS: 0,
  ACTOR_PASS: 0,
  COMPOSITE_INDEX_PASS: 1,
  ID_LOW24: 2,
  ID_HIGH24: 3,
  MAX_KNOWN_PASS: 3
};
var Constants = {
  PassTypes: PassTypes
};

export { PassTypes, Constants as default };

var ColorSpace = {
  RGB: 0,
  HSV: 1,
  LAB: 2,
  DIVERGING: 3
};
var Scale = {
  LINEAR: 0,
  LOG10: 1
};
var Constants = {
  ColorSpace: ColorSpace,
  Scale: Scale
};

export { ColorSpace, Scale, Constants as default };

import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import vtk from '../../vtk.js';
import vtkCellArray from '../Core/CellArray.js';
import vtkCellLinks from './CellLinks.js';
import vtkCellTypes from './CellTypes.js';
import vtkLine from './Line.js';
import vtkPointSet from './PointSet.js';
import vtkTriangle from './Triangle.js';
import { CellType } from './CellTypes/Constants.js';
import { POLYDATA_FIELDS } from './PolyData/Constants.js';

var _CELL_FACTORY;
var vtkWarningMacro = macro.vtkWarningMacro;
var CELL_FACTORY = (_CELL_FACTORY = {}, _defineProperty(_CELL_FACTORY, CellType.VTK_LINE, vtkLine), _defineProperty(_CELL_FACTORY, CellType.VTK_POLY_LINE, vtkLine), _defineProperty(_CELL_FACTORY, CellType.VTK_TRIANGLE, vtkTriangle), _CELL_FACTORY); // ----------------------------------------------------------------------------
// vtkPolyData methods
// ----------------------------------------------------------------------------

function vtkPolyData(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkPolyData');

  function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter) {
      return letter.toUpperCase();
    }).replace(/\s+/g, '');
  } // build empty cell arrays and set methods


  POLYDATA_FIELDS.forEach(function (type) {
    publicAPI["getNumberOf".concat(camelize(type))] = function () {
      return model[type].getNumberOfCells();
    };

    if (!model[type]) {
      model[type] = vtkCellArray.newInstance();
    } else {
      model[type] = vtk(model[type]);
    }
  });

  publicAPI.getNumberOfCells = function () {
    return POLYDATA_FIELDS.reduce(function (num, cellType) {
      return num + model[cellType].getNumberOfCells();
    }, 0);
  };

  var superShallowCopy = publicAPI.shallowCopy;

  publicAPI.shallowCopy = function (other) {
    var debug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    superShallowCopy(other, debug);
    POLYDATA_FIELDS.forEach(function (type) {
      model[type] = vtkCellArray.newInstance();
      model[type].shallowCopy(other.getReferenceByName(type));
    });
  };

  publicAPI.buildCells = function () {
    // here are the number of cells we have
    var nVerts = publicAPI.getNumberOfVerts();
    var nLines = publicAPI.getNumberOfLines();
    var nPolys = publicAPI.getNumberOfPolys();
    var nStrips = publicAPI.getNumberOfStrips(); // pre-allocate the space we need

    var nCells = nVerts + nLines + nPolys + nStrips;
    var types = new Uint8Array(nCells);
    var pTypes = types;
    var locs = new Uint32Array(nCells);
    var pLocs = locs; // record locations and type of each cell.
    // verts

    if (nVerts) {
      var nextCellPts = 0;
      model.verts.getCellSizes().forEach(function (numCellPts, index) {
        pLocs[index] = nextCellPts;
        pTypes[index] = numCellPts > 1 ? CellType.VTK_POLY_VERTEX : CellType.VTK_VERTEX;
        nextCellPts += numCellPts + 1;
      });
      pLocs = pLocs.subarray(nVerts);
      pTypes = pTypes.subarray(nVerts);
    } // lines


    if (nLines) {
      var _nextCellPts = 0;
      model.lines.getCellSizes().forEach(function (numCellPts, index) {
        pLocs[index] = _nextCellPts;
        pTypes[index] = numCellPts > 2 ? CellType.VTK_POLY_LINE : CellType.VTK_LINE;

        if (numCellPts === 1) {
          vtkWarningMacro('Building VTK_LINE ', index, ' with only one point, but VTK_LINE needs at least two points. Check the input.');
        }

        _nextCellPts += numCellPts + 1;
      });
      pLocs = pLocs.subarray(nLines);
      pTypes = pTypes.subarray(nLines);
    } // polys


    if (nPolys) {
      var _nextCellPts2 = 0;
      model.polys.getCellSizes().forEach(function (numCellPts, index) {
        pLocs[index] = _nextCellPts2;

        switch (numCellPts) {
          case 3:
            pTypes[index] = CellType.VTK_TRIANGLE;
            break;

          case 4:
            pTypes[index] = CellType.VTK_QUAD;
            break;

          default:
            pTypes[index] = CellType.VTK_POLYGON;
            break;
        }

        if (numCellPts < 3) {
          vtkWarningMacro('Building VTK_TRIANGLE ', index, ' with less than three points, but VTK_TRIANGLE needs at least three points. Check the input.');
        }

        _nextCellPts2 += numCellPts + 1;
      });
      pLocs += pLocs.subarray(nPolys);
      pTypes += pTypes.subarray(nPolys);
    } // strips


    if (nStrips) {
      var _nextCellPts3 = 0;
      pTypes.fill(CellType.VTK_TRIANGLE_STRIP, 0, nStrips);
      model.strips.getCellSizes().forEach(function (numCellPts, index) {
        pLocs[index] = _nextCellPts3;
        _nextCellPts3 += numCellPts + 1;
      });
    } // set up the cell types data structure


    model.cells = vtkCellTypes.newInstance();
    model.cells.setCellTypes(nCells, types, locs);
  };
  /**
   * Create upward links from points to cells that use each point. Enables
   * topologically complex queries.
   */


  publicAPI.buildLinks = function () {
    var initialSize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    if (model.cells === undefined) {
      publicAPI.buildCells();
    }

    model.links = vtkCellLinks.newInstance();

    if (initialSize > 0) {
      model.links.allocate(initialSize);
    } else {
      model.links.allocate(publicAPI.getPoints().getNumberOfPoints());
    }

    model.links.buildLinks(publicAPI);
  };

  publicAPI.getCellType = function (cellId) {
    return model.cells.getCellType(cellId);
  };

  publicAPI.getCellPoints = function (cellId) {
    var cellType = publicAPI.getCellType(cellId);
    var cells = null;

    switch (cellType) {
      case CellType.VTK_VERTEX:
      case CellType.VTK_POLY_VERTEX:
        cells = model.verts;
        break;

      case CellType.VTK_LINE:
      case CellType.VTK_POLY_LINE:
        cells = model.lines;
        break;

      case CellType.VTK_TRIANGLE:
      case CellType.VTK_QUAD:
      case CellType.VTK_POLYGON:
        cells = model.polys;
        break;

      case CellType.VTK_TRIANGLE_STRIP:
        cells = model.strips;
        break;

      default:
        cells = null;
        return {
          type: 0,
          cellPointIds: null
        };
    }

    var loc = model.cells.getCellLocation(cellId);
    var cellPointIds = cells.getCell(loc);
    return {
      cellType: cellType,
      cellPointIds: cellPointIds
    };
  };

  publicAPI.getPointCells = function (ptId) {
    return model.links.getCells(ptId);
  };

  publicAPI.getCellEdgeNeighbors = function (cellId, point1, point2) {
    var link1 = model.links.getLink(point1);
    var link2 = model.links.getLink(point2);
    return link1.cells.filter(function (cell) {
      return cell !== cellId && link2.cells.indexOf(cell) !== -1;
    });
  };
  /**
   * If you know the type of cell, you may provide it to improve performances.
   */


  publicAPI.getCell = function (cellId) {
    var cellHint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var cellInfo = publicAPI.getCellPoints(cellId);
    var cell = cellHint || CELL_FACTORY[cellInfo.cellType].newInstance();
    cell.initialize(publicAPI.getPoints(), cellInfo.cellPointIds);
    return cell;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {// verts: null,
  // lines: null,
  // polys: null,
  // strips: null,
  // cells: null,
  // links: null,
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkPointSet.extend(publicAPI, model, initialValues);
  macro.get(publicAPI, model, ['cells', 'links']);
  macro.setGet(publicAPI, model, ['verts', 'lines', 'polys', 'strips']); // Object specific methods

  vtkPolyData(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkPolyData'); // ----------------------------------------------------------------------------

var vtkPolyData$1 = {
  newInstance: newInstance,
  extend: extend
};

export { CELL_FACTORY, vtkPolyData$1 as default, extend, newInstance };

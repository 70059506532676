var StructuredType = {
  UNCHANGED: 0,
  SINGLE_POINT: 1,
  X_LINE: 2,
  Y_LINE: 3,
  Z_LINE: 4,
  XY_PLANE: 5,
  YZ_PLANE: 6,
  XZ_PLANE: 7,
  XYZ_GRID: 8,
  EMPTY: 9
};
var Constants = {
  StructuredType: StructuredType
};

export { StructuredType, Constants as default };

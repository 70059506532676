import macro from '../../macros.js';
import vtkWebGPUShaderCache from './ShaderCache.js';

// vtkWebGPUShaderDescription methods
// ----------------------------------------------------------------------------
// shader description

function vtkWebGPUShaderDescription(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUShaderDescription');

  publicAPI.hasOutput = function (name) {
    return model.outputNames.includes(name);
  };

  publicAPI.addOutput = function (type, name) {
    var interpolation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
    model.outputTypes.push(type);
    model.outputNames.push(name);
    model.outputInterpolations.push(interpolation);
  };

  publicAPI.addBuiltinOutput = function (type, name) {
    model.builtinOutputTypes.push(type);
    model.builtinOutputNames.push(name);
  };

  publicAPI.addBuiltinInput = function (type, name) {
    model.builtinInputTypes.push(type);
    model.builtinInputNames.push(name);
  }; // perform shader replacements for the input and outputs
  // of this shader. That includes vertex inputs if specified


  publicAPI.replaceShaderCode = function (priorStage, vertexInput) {
    var inputImpl = [];
    var iodec = [];

    if (vertexInput) {
      inputImpl.push(vertexInput.getShaderCode());
    }

    if (priorStage || model.builtinInputNames.length) {
      var inputStruct = [];
      inputStruct.push("struct ".concat(model.type, "Input\n{"));

      if (priorStage) {
        var inputNames = priorStage.getOutputNamesByReference();
        var inputTypes = priorStage.getOutputTypesByReference();
        var inputInterpolations = priorStage.getOutputInterpolationsByReference();

        for (var i = 0; i < inputNames.length; i++) {
          if (inputInterpolations[i] !== undefined) {
            inputStruct.push("  @location(".concat(i, ") @interpolate(").concat(inputInterpolations[i], ") ").concat(inputNames[i], " : ").concat(inputTypes[i], ","));
          } else {
            inputStruct.push("  @location(".concat(i, ") ").concat(inputNames[i], " : ").concat(inputTypes[i], ","));
          }
        }
      }

      for (var _i = 0; _i < model.builtinInputNames.length; _i++) {
        inputStruct.push("  ".concat(model.builtinInputNames[_i], " : ").concat(model.builtinInputTypes[_i], ","));
      }

      if (inputStruct.length > 1) {
        inputStruct.push('};');
        iodec = inputStruct;
        inputImpl[inputImpl.length - 1] += ',';
        inputImpl.push("input: ".concat(model.type, "Input"));
      }
    }

    if (inputImpl.length) {
      model.code = vtkWebGPUShaderCache.substitute(model.code, '//VTK::IOStructs::Input', inputImpl).result;
    }

    if (model.outputNames.length + model.builtinOutputNames.length) {
      var outputStruct = ["struct ".concat(model.type, "Output\n{")];

      for (var _i2 = 0; _i2 < model.outputNames.length; _i2++) {
        if (model.outputInterpolations[_i2] !== undefined) {
          outputStruct.push("  @location(".concat(_i2, ") @interpolate(").concat(model.outputInterpolations[_i2], ") ").concat(model.outputNames[_i2], " : ").concat(model.outputTypes[_i2], ","));
        } else {
          outputStruct.push("  @location(".concat(_i2, ") ").concat(model.outputNames[_i2], " : ").concat(model.outputTypes[_i2], ","));
        }
      }

      for (var _i3 = 0; _i3 < model.builtinOutputNames.length; _i3++) {
        outputStruct.push("  ".concat(model.builtinOutputNames[_i3], " : ").concat(model.builtinOutputTypes[_i3], ","));
      }

      outputStruct.push('};');
      iodec = iodec.concat(outputStruct);
      model.code = vtkWebGPUShaderCache.substitute(model.code, '//VTK::IOStructs::Output', ["-> ".concat(model.type, "Output")]).result;
    }

    model.code = vtkWebGPUShaderCache.substitute(model.code, '//VTK::IOStructs::Dec', iodec).result;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  type: null,
  // 'vertex' or 'fragment'
  hash: null,
  code: null,
  outputNames: null,
  outputTypes: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  model.outputNames = [];
  model.outputTypes = [];
  model.outputInterpolations = [];
  model.builtinOutputNames = [];
  model.builtinOutputTypes = [];
  model.builtinInputNames = [];
  model.builtinInputTypes = []; // Build VTK API

  macro.obj(publicAPI, model);
  macro.setGet(publicAPI, model, ['type', 'hash', 'code']);
  macro.getArray(publicAPI, model, ['outputTypes', 'outputNames', 'outputInterpolations']); // Object methods

  vtkWebGPUShaderDescription(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkWebGPUShaderDescription'); // ----------------------------------------------------------------------------

var vtkWebGPUShaderDescription$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkWebGPUShaderDescription$1 as default, extend, newInstance };

var SlicingMode = {
  NONE: -1,
  I: 0,
  J: 1,
  K: 2,
  X: 3,
  Y: 4,
  Z: 5
};
var Constants = {
  SlicingMode: SlicingMode
};

export { SlicingMode, Constants as default };

import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import { CellTypesStrings, CellType } from './CellTypes/Constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// Global methods
// ----------------------------------------------------------------------------

/**
 * Given an int (as defined in vtkCellType.h) identifier for a class
 * return it's classname.
 */

function getClassNameFromTypeId(typeId) {
  return typeId < CellTypesStrings.length ? CellTypesStrings[typeId] : 'UnknownClass';
}
/**
 * Given a data object classname, return it's int identified (as
 * defined in vtkCellType.h)
 */


function getTypeIdFromClassName(cellTypeString) {
  return CellTypesStrings.findIndex(cellTypeString);
}
/**
 * This convenience method is a fast check to determine if a cell type
 * represents a linear or nonlinear cell.  This is generally much more
 * efficient than getting the appropriate vtkCell and checking its IsLinear
 * method.
 */


function isLinear(type) {
  return type < CellType.VTK_QUADRATIC_EDGE || type === CellType.VTK_CONVEX_POINT_SET || type === CellType.VTK_POLYHEDRON;
}

function hasSubCells(cellType) {
  return cellType === CellType.VTK_TRIANGLE_STRIP || cellType === CellType.VTK_POLY_LINE || cellType === CellType.VTK_POLY_VERTEX;
} // ----------------------------------------------------------------------------
// Static API
// ----------------------------------------------------------------------------


var STATIC = {
  getClassNameFromTypeId: getClassNameFromTypeId,
  getTypeIdFromClassName: getTypeIdFromClassName,
  isLinear: isLinear,
  hasSubCells: hasSubCells
}; // ----------------------------------------------------------------------------
// vtkCellTypes methods
// ----------------------------------------------------------------------------

function vtkCellTypes(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkCellTypes');
  /**
   * Allocate memory for this array. Delete old storage only if necessary.
   */

  publicAPI.allocate = function () {
    var sz = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 512;
    var ext = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
    model.size = sz > 0 ? sz : 1;
    model.extend = ext > 0 ? ext : 1;
    model.maxId = -1;
    model.typeArray = new Uint8Array(sz);
    model.locationArray = new Uint32Array(sz);
  };
  /**
   * Add a cell at specified id.
   */


  publicAPI.insertCell = function (cellId, type, loc) {
    model.typeArray[cellId] = type;
    model.locationArray[cellId] = loc;

    if (cellId > model.maxId) {
      model.maxId = cellId;
    }
  };
  /**
   * Add a cell to the object in the next available slot.
   */


  publicAPI.insertNextCell = function (type, loc) {
    publicAPI.insertCell(++model.maxId, type, loc);
    return model.maxId;
  };
  /**
   * Specify a group of cell types. This version is provided to maintain
   * backwards compatibility and does a copy of the cellLocations
   */


  publicAPI.setCellTypes = function (ncells, cellTypes, cellLocations) {
    model.size = ncells;
    model.typeArray = cellTypes;
    model.locationArray = cellLocations;
    model.maxId = ncells - 1;
  };
  /**
   * Return the location of the cell in the associated vtkCellArray.
   */


  publicAPI.getCellLocation = function (cellId) {
    return model.locationArray[cellId];
  };
  /**
   * Delete cell by setting to nullptr cell type.
   */


  publicAPI.deleteCell = function (cellId) {
    model.typeArray[cellId] = CellType.VTK_EMPTY_CELL;
  };
  /**
   * Return the number of types in the list.
   */


  publicAPI.getNumberOfTypes = function () {
    return model.maxId + 1;
  };
  /**
   * Return true if type specified is contained in list; false otherwise.
   */


  publicAPI.isType = function (type) {
    var numTypes = publicAPI.getNumberOfTypes();

    for (var i = 0; i < numTypes; ++i) {
      if (type === publicAPI.getCellType(i)) {
        return true;
      }
    }

    return false;
  };
  /**
   * Add the type specified to the end of the list. Range checking is performed.
   */


  publicAPI.insertNextType = function (type) {
    return publicAPI.insertNextCell(type, -1);
  };
  /**
   * Return the type of cell.
   */


  publicAPI.getCellType = function (cellId) {
    return model.typeArray[cellId];
  };
  /**
   * Reclaim any extra memory.
   */
  // TODO: publicAPI.squeeze = () =>  {};

  /**
   * Initialize object without releasing memory.
   */


  publicAPI.reset = function () {
    model.maxId = -1;
  };
  /**
   * Standard DeepCopy method.  Since this object contains no reference
   * to other objects, there is no ShallowCopy.
   */


  publicAPI.deepCopy = function (src) {
    publicAPI.allocate(src.getSize(), src.getExtend());
    model.typeArray.set(src.getTypeArray());
    model.locationArray.set(src.getLocationArray());
    model.maxId = src.getMaxId();
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  // typeArray: null, // pointer to types array
  // locationArray: null;   // pointer to array of offsets
  size: 0,
  // allocated size of data
  maxId: -1,
  // maximum index inserted thus far
  extend: 1000 // grow array by this point

}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  macro.obj(publicAPI, model);
  macro.get(publicAPI, model, ['size', 'maxId', 'extend']);
  macro.getArray(publicAPI, model, ['typeArray', 'locationArray']);
  vtkCellTypes(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkCellTypes'); // ----------------------------------------------------------------------------

var vtkCellTypes$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, STATIC);

export { STATIC, vtkCellTypes$1 as default, extend, newInstance };

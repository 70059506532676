import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import Constants from './SelectionNode/Constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// vtkSelectionNode methods
// ----------------------------------------------------------------------------

function vtkSelectionNode(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkSelectionNode');

  publicAPI.getBounds = function () {
    return model.points.getBounds();
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  contentType: -1,
  fieldType: -1,
  properties: null,
  selectionList: []
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  macro.obj(publicAPI, model);
  model.properties = {};
  macro.setGet(publicAPI, model, ['contentType', 'fieldType', 'properties', 'selectionList']); // Object specific methods

  vtkSelectionNode(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkSelectionNode'); // ----------------------------------------------------------------------------

var vtkSelectionNode$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, Constants);

export { vtkSelectionNode$1 as default, extend, newInstance };

import _typeof from '@babel/runtime/helpers/typeof';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import globalThisShim from 'globalthis';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var vtkGlobal = globalThisShim(); // returns native globalThis if compliant

var factoryMapping = {
  vtkObject: function vtkObject() {
    return null;
  }
};
function vtk(obj) {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (obj.isA) {
    return obj;
  }

  if (!obj.vtkClass) {
    if (vtkGlobal.console && vtkGlobal.console.error) {
      vtkGlobal.console.error('Invalid VTK object');
    }

    return null;
  }

  var constructor = factoryMapping[obj.vtkClass];

  if (!constructor) {
    if (vtkGlobal.console && vtkGlobal.console.error) {
      vtkGlobal.console.error("No vtk class found for Object of type ".concat(obj.vtkClass));
    }

    return null;
  } // Shallow copy object


  var model = _objectSpread({}, obj); // Convert into vtkObject any nested key


  Object.keys(model).forEach(function (keyName) {
    if (model[keyName] && _typeof(model[keyName]) === 'object' && model[keyName].vtkClass) {
      model[keyName] = vtk(model[keyName]);
    }
  }); // Return the root

  var newInst = constructor(model);

  if (newInst && newInst.modified) {
    newInst.modified();
  }

  return newInst;
}

function register(vtkClassName, constructor) {
  factoryMapping[vtkClassName] = constructor;
} // Nest register method under the vtk function


vtk.register = register;

export { vtk as default, vtkGlobal };

import macro from '../../macros.js';

// vtkWebGPUBindGroup methods
// ----------------------------------------------------------------------------

function vtkWebGPUBindGroup(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUBindGroup');

  publicAPI.setBindables = function (bindables) {
    // is there a difference between the old and new list?
    if (model.bindables.length === bindables.length) {
      var allMatch = true;

      for (var i = 0; i < model.bindables.length; i++) {
        if (model.bindables[i] !== bindables[i]) {
          allMatch = false;
        }
      }

      if (allMatch) {
        return;
      }
    } // there is a difference


    model.bindables = bindables;
    publicAPI.modified();
  };

  publicAPI.getBindGroupLayout = function (device) {
    var entries = [];

    for (var i = 0; i < model.bindables.length; i++) {
      var entry = model.bindables[i].getBindGroupLayoutEntry();
      entry.binding = i;
      entries.push(entry);
    }

    return device.getBindGroupLayout({
      entries: entries
    });
  };

  publicAPI.getBindGroup = function (device) {
    // check mtime
    var mtime = publicAPI.getMTime();

    for (var i = 0; i < model.bindables.length; i++) {
      var tm = model.bindables[i].getBindGroupTime().getMTime();
      mtime = tm > mtime ? tm : mtime;
    }

    if (mtime < model.bindGroupTime.getMTime()) {
      return model.bindGroup;
    }

    var entries = [];

    for (var _i = 0; _i < model.bindables.length; _i++) {
      var entry = model.bindables[_i].getBindGroupEntry();

      entry.binding = _i;
      entries.push(entry);
    }

    model.bindGroup = device.getHandle().createBindGroup({
      layout: publicAPI.getBindGroupLayout(device),
      entries: entries,
      label: model.label
    });
    model.bindGroupTime.modified();
    return model.bindGroup;
  };

  publicAPI.getShaderCode = function (pipeline) {
    var lines = [];
    var bgroup = pipeline.getBindGroupLayoutCount(model.label);

    for (var i = 0; i < model.bindables.length; i++) {
      lines.push(model.bindables[i].getShaderCode(i, bgroup));
    }

    return lines.join('\n');
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  device: null,
  handle: null,
  label: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Object methods

  macro.obj(publicAPI, model);
  model.bindables = [];
  model.bindGroupTime = {};
  macro.obj(model.bindGroupTime, {
    mtime: 0
  });
  macro.get(publicAPI, model, ['bindGroupTime', 'handle', 'sizeInBytes', 'usage']);
  macro.setGet(publicAPI, model, ['label', 'device', 'arrayInformation']);
  vtkWebGPUBindGroup(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend); // ----------------------------------------------------------------------------

var vtkWebGPUBindGroup$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkWebGPUBindGroup$1 as default, extend, newInstance };
